<template>
  <div class="profile">
    <div class="profile__body">
      <v-card class="elevation-4">
        <v-col class="d-md-flex flex-column pa-7 pa-sm-12 col">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            action="/dashboards/view"
          >
            <div class="profile__email">{{ user.email }}</div>
            <v-btn-toggle
              class="mb-4 toggle-btn"
              v-model="registerType"
              mandatory
            >
              <v-btn>Individual</v-btn>

              <v-btn>Legal Entity</v-btn>
            </v-btn-toggle>
            <v-text-field
              v-model="fname"
              hide-details="auto"
              :rules="fnameRules"
              label="First Name"
              class="mt-4"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="lname"
              hide-details="auto"
              :rules="lnameRules"
              label="Last Name"
              class="mt-4"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="mname"
              hide-details="auto"
              :rules="mnameRules"
              label="Middle Name"
              class="mt-4"
              required
              outlined
            ></v-text-field>
            <VuePhoneNumberInput
              v-model="phone"
              required
              no-country-selector
              class="mt-3 mb-12 phone-number-input"
              :only-countries="['US']"
              @input="updateValue('phone')"
            />

            <v-text-field
              v-if="registerType === 1"
              v-model="cname"
              hide-details="auto"
              :rules="cnameRules"
              label="Full Legal Company Name"
              class="mt-4"
              required
              outlined
            ></v-text-field>

            <div class="myrow">
              <VueGooglePlaces
                :api-key="apiKey"
                ref="googleSearch"
                country="usa"
                class="google-place-search k-width-1-1"
                placeholder="Address"
                v-model="hStreet"
              />
            </div>

            

            <v-text-field
              v-if="registerType === 0"
              v-model="hSsn"
              hide-details="auto"
              :rules="hssnRules"
              label="SSN"
              required
              outlined
              class="mt-4"
              disabled
            ></v-text-field>

            
            <v-text-field
              v-if="registerType === 1"
              v-model="cTax"
              hide-details="auto"
              :rules="ctaxRules"
              label="Tax ID/EIN"
              required
              outlined
              class="mt-4"
              disabled
            ></v-text-field>

            <div class="myrow">
              <v-text-field
                v-model="licenseName"
                hide-details="auto"
                :rules="licenseNameRules"
                label="Name on the License"
                required
                outlined
                class="mt-4 mr-3"
              ></v-text-field>
              <v-text-field
                v-model="licenseState"
                hide-details="auto"
                :rules="licenseStateRules"
                label="Driver License State"
                required
                outlined
                class="mt-4"
              ></v-text-field>
            </div>
            <div class="myrow">
              <v-text-field
                v-model="iDate"
                hide-details="auto"
                :rules="iDateRules"
                label="Issue Date"
                required
                outlined
                class="mt-4 mr-3"
              ></v-text-field>
              <v-text-field
                v-model="eDate"
                hide-details="auto"
                :rules="eDateRules"
                label="Expiration Date"
                required
                outlined
                class="mt-4"
              ></v-text-field>
            </div>
            <div class="myrow">
              <v-text-field
                v-model="bDate"
                hide-details="auto"
                :rules="bDateRules"
                label="Date of Birth"
                required
                outlined
                class="mt-4 mr-3"
              ></v-text-field>
              <v-text-field
                v-model="licenseNumber"
                hide-details="auto"
                :rules="licenseNumberRules"
                label="Licence Number"
                required
                outlined
                class="mt-4"
              ></v-text-field>
            </div>

            <v-text-field
              v-if="registerType === 0"
              v-model="hInsuredName"
              hide-details="auto"
              :rules="hInsuredNameRules"
              label="Insured Name"
              required
              outlined
              class="mt-4"
            ></v-text-field>

            <v-text-field
              v-if="registerType === 1"
              v-model="cInsuranceName"
              hide-details="auto"
              :rules="cinsuranceNameRules"
              label="Insurance Company Name"
              required
              outlined
              class="mt-4"
            ></v-text-field>
            <div class="myrow">
              <v-text-field
                v-model="effectiveDate"
                hide-details="auto"
                :rules="effectiveDateRules"
                label="Insurance Effective Date"
                required
                outlined
                class="mt-4 mr-3"
              ></v-text-field>
              <v-text-field
                v-model="expirationDate"
                hide-details="auto"
                :rules="expirationDateRules"
                label="Insurance Expiration Date"
                required
                outlined
                class="mt-4 mr-3"
              ></v-text-field>
              <v-text-field
                v-model="policyNumber"
                hide-details="auto"
                :rules="policyNumberRules"
                label="Policy Number"
                required
                outlined
                class="mt-4"
              ></v-text-field>
            </div>

            <div class="myrow">
              <v-text-field
                v-model="vehicleYear"
                hide-details="auto"
                :rules="vehicleYearRules"
                label="Vehicle Year"
                required
                outlined
                class="mt-4 mr-3"
              ></v-text-field>
              <v-text-field
                v-model="vehicleMake"
                hide-details="auto"
                :rules="vehicleMakeRules"
                label="Vehicle Make & Model"
                required
                outlined
                class="mt-4"
              ></v-text-field>
            </div>

            <div class="myrow">
              <v-text-field
                v-model="vehicleColor"
                hide-details="auto"
                :rules="vehicleColorRules"
                label="Vehicle Color"
                required
                outlined
                class="mt-4 mr-3"
              ></v-text-field>
              <v-text-field
                v-model="vehicleType"
                hide-details="auto"
                :rules="vehicleTypeRulse"
                label="Vehicle Type"
                required
                outlined
                class="mt-4"
              ></v-text-field>
            </div>

            <div class="upload-btns">
              <!-- <upload-btn labelClass="mylabel" title="Upload Insurance ID Card"></upload-btn>
                <upload-btn labelClass="mylabel" title="Upload License"></upload-btn>
              <upload-btn labelClass="mylabel" title="Upload Driver's Picture"></upload-btn>-->
              <v-file-input
                label="Upload Insurance ID Card"
                class="myinput"
                accept="image/*"
              ></v-file-input>
              <v-file-input
                label="Upload License"
                class="myinput"
                accept="image/*"
              ></v-file-input>
              <v-file-input
                label="Upload Driver's Picture"
                class="myinput"
                accept="image/*"
              ></v-file-input>
            </div>

            <div class="my-2 d-flex justify-center">
              <v-btn color="primary" @click.native="submit">Save</v-btn>
            </div>
          </v-form>
        </v-col>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar">
      {{ notification }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#fc4b6c" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    valid: true,
    registerType: 0,
    //
    phone: "",
    //
    fname: "",
    fnameRules: [
      (v) => !!v || "First Name is required",
      // (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    lname: "",
    lnameRules: [
      (v) => !!v || "Last Name is required",
      // (v) =>
      // (v && v.length <= 10) || "Last Name must be less than 10 characters",
    ],
    mname: "",
    mnameRules: [
      (v) => !!v || "Middle Name is required",
      // (v) =>
      // (v && v.length <= 10) || "Middle Name must be less than 10 characters",
    ],
    cname: "",
    cnameRules: [
      (v) => !!v || "Company Name is required",
      // (v) =>
      // (v && v.length <= 10) || "Company Name must be less than 10 characters",
    ],

    hStreet: "",
    hstreetRules: [
      (v) => !!v || "Street is required",
      // (v) => (v && v.length <= 10) || "hStreet must be less than 10 characters",
    ],
    // hCity: "",
    // hcityRules: [
    //   (v) => !!v || "City is required",
    //   (v) => (v && v.length <= 10) || "City must be less than 10 characters",
    // ],
    // hState: "",
    // hstateRules: [
    //   (v) => !!v || "State is required",
    //   (v) => (v && v.length <= 10) || "State must be less than 10 characters",
    // ],
    // hZip: "",
    // hzipRules: [
    //   (v) => !!v || "Zip is required",
    //   (v) => (v && v.length <= 10) || "Zip must be less than 10 characters",
    // ],
    hSsn: "",
    hssnRules: [
      // (v) => !!v || "SSN is required",
      // (v) => (v && v.length <= 10) || "SSN must be less than 10 characters",
    ],
    hInsuredName: "",
    hInsuredNameRules: [
      (v) => !!v || "Insured Name is required",
      // (v) =>
      //   (v && v.length <= 10) || "Insured Name must be less than 10 characters",
    ],

    // cStreet: "",
    // cstreetRules: [
    //   (v) => !!v || "Street is required",
    //   // (v) => (v && v.length <= 10) || "hStreet must be less than 10 characters",
    // ],
    // cCity: "",
    // ccityRules: [
    //   (v) => !!v || "City is required",
    //   (v) => (v && v.length <= 10) || "City must be less than 10 characters",
    // ],
    // cState: "",
    // cstateRules: [
    //   (v) => !!v || "State is required",
    //   (v) => (v && v.length <= 10) || "State must be less than 10 characters",
    // ],
    // cZip: "",
    // czipRules: [
    //   (v) => !!v || "Zip is required",
    //   (v) => (v && v.length <= 10) || "Zip must be less than 10 characters",
    // ],
    cTax: "",
    ctaxRules: [
      (v) => !!v || "Tax is required",
      // (v) => (v && v.length <= 10) || "Tax must be less than 10 characters",
    ],

    cInsuranceName: "",
    cinsuranceNameRules: [
      (v) => !!v || "Insured Name is required",
      // (v) =>
      //   (v && v.length <= 10) || "Insured Name must be less than 10 characters",
    ],

    licenseName: "",
    licenseNameRules: [
      (v) => !!v || "License Name is required",
      // (v) =>
      //   (v && v.length <= 10) || "License Name must be less than 10 characters",
    ],

    licenseState: "",
    licenseStateRules: [
      (v) => !!v || "License State is required",
      // (v) =>
      //   (v && v.length <= 10) ||
      //   "License State must be less than 10 characters",
    ],

    iDate: "",
    iDateRules: [
      (v) => !!v || "Issue Date is required",
      // (v) =>
      //   (v && v.length <= 10) || "Issue Date must be less than 10 characters",
    ],

    eDate: "",
    eDateRules: [
      (v) => !!v || "Expiration Date is required",
      // (v) =>
      //   (v && v.length <= 10) ||
      //   "Expiration Date must be less than 10 characters",
    ],

    bDate: "",
    bDateRules: [
      (v) => !!v || "Date of Birth is required",
      // (v) =>
      //   (v && v.length <= 10) ||
      //   "Date of Birth must be less than 10 characters",
    ],

    licenseNumber: "",
    licenseNumberRules: [
      (v) => !!v || "Licence Number is required",
      // (v) =>
      //   (v && v.length <= 10) ||
      //   "Licence Number must be less than 10 characters",
    ],

    effectiveDate: "",
    effectiveDateRules: [
      (v) => !!v || "Insurance Effective Date is required",
      // (v) =>
      //   (v && v.length <= 10) ||
      //   "Effective Date must be less than 10 characters",
    ],

    expirationDate: "",
    expirationDateRules: [
      (v) => !!v || "Insurance Expiration Date is required",
      // (v) =>
      //   (v && v.length <= 10) ||
      //   "Expiration Date must be less than 10 characters",
    ],

    policyNumber: "",
    policyNumberRules: [
      (v) => !!v || "Policy Number is required",
      // (v) =>
      //   (v && v.length <= 10) ||
      //   "Policy Number must be less than 10 characters",
    ],

    vehicleYear: "",
    vehicleYearRules: [
      (v) => !!v || "Vehicle Year is required",
      // (v) =>
      //   (v && v.length <= 10) || "Vehicle Year must be less than 10 characters",
    ],

    vehicleMake: "",
    vehicleMakeRules: [
      (v) => !!v || "Vehicle Make & Model is required",
      // (v) =>
      //   (v && v.length <= 10) ||
      //   "Vehicle Make & Model must be less than 10 characters",
    ],

    vehicleColor: "",
    vehicleColorRules: [
      (v) => !!v || "Vehicle Color Date is required",
      // (v) =>
      //   (v && v.length <= 10) ||
      // "Vehicle Color Date must be less than 10 characters",
    ],

    vehicleType: "",
    vehicleTypeRulse: [
      (v) => !!v || "Vehicle Type is required",
      // (v) =>
      //   (v && v.length <= 10) || "Vehicle Type must be less than 10 characters",
    ],
    //
    apiKey: "AIzaSyBFwsSWdZdUYzlomq7JyucZdEx8hTEjRnU",
    //
    snackbar: false,
    notification: "",
  }),
  mounted() {
    var u = this.user;
    var p = u.profile;

    this.fname = u.firstName;
    this.lname = u.lastName;
    this.mname = u.middleName;
    this.phone = u.phone;

    if (this.user.profile) {
      this.registerType = p.registerType;
      this.hStreet = p.address;
      //
      this.hSsn = p.ssn;
      //
      this.cname = p.companyName;
      this.cTax = p.taxID;
      //
      this.licenseName = p.licenseName;
      this.licenseState = p.licenseState;
      this.iDate = p.licenceEffectiveDate;
      this.eDate = p.licenceeEpirationDate;
      this.bDate = p.dateOfBirth;
      this.licenseNumber = p.licenseNumber;
      //
      this.hInsuredName = p.insuredName;
      this.cInsuranceName = p.insuranceCompanyName;
      this.effectiveDate = p.insuranceEffectiveDate;
      this.expirationDate = p.insuranceExpirationDate;
      this.policyNumber = p.policyNumber;
      //
      this.vehicleYear = p.vehicleYear;
      this.vehicleMake = p.vehicleMake;
      this.vehicleColor = p.vehicleColor;
      this.vehicleType = p.vehicleType;
      //
      //
      //
      this.$nextTick(() => {
        this.$refs.googleSearch.textValue = p.address;
      });
    }
  },
  computed: {
    ...mapGetters(["user", "strapi"]),
  },
  methods: {
 
    submit() {
      // console.log("submit");
      // console.log(this.$refs.form.validate());
      if (this.$refs.form.validate(true)) {
        // console.log("validate(true)");
        var updatedProfile = {
          registerType: this.registerType,
          address: this.hStreet,
          //
          ssn: this.registerType == 0 ? this.hSsn : undefined,
          //
          companyName: this.registerType == 1 ? this.cname : undefined,
          taxID: this.registerType == 1 ? this.cTax : undefined,
          //
          licenseName: this.licenseName,
          licenseState: this.licenseState,
          licenceEffectiveDate: this.iDate,
          licenceeEpirationDate: this.eDate,
          dateOfBirth: this.bDate,
          licenseNumber: this.licenseNumber,
          //
          insuredName: this.hInsuredName,
          insuranceCompanyName: this.cInsuranceName,
          insuranceEffectiveDate: this.effectiveDate,
          insuranceExpirationDate: this.effectiveDate,
          policyNumber: this.policyNumber,
          //
          vehicleYear: this.vehicleYear,
          vehicleMake: this.vehicleMake,
          vehicleColor: this.vehicleColor,
          vehicleType: this.vehicleType,
          //
        };

        this.$store
          .dispatch("auth/updateProfile", {
            firstName: this.fname,
            lastName: this.lname,
            middleName: this.mname,
            phone: this.phone,
            profile: updatedProfile,
          })
          .then((response) => {
            this.notification = "Successfull";
            this.snackbar = true;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    updateValue(val) {
      if (!this.phone) {
        this.phone = this.user.phone;
      }
      var valClean = this[val] ? this[val].replace(/\D/g, "") : this[val];
      if (valClean && valClean.length > 10) {
        this[val] = this.formatPhoneNumber(valClean.substring(0, 10));
        this.$forceUpdate();
      }
    },

    formatPhoneNumber(str) {
      //Filter only numbers from the input
      let cleaned = ("" + str).replace(/\D/g, "");

      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  width: 100%;
  padding: 40px 0;

  &__body {
    width: 100%;
    max-width: 1100px;

    margin: 0px auto;

    padding: 0 25px;
  }

  &__email {
    margin-bottom: 30px;
  }
}

.img {
  width: 100%;
  max-width: 140px;
}

.myrow {
  display: flex;
  flex-direction: row;
}

.main-container {
  @media screen and (max-width: 959px) {
    flex-direction: column !important;
  }
}

.mycontainer {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.col {
  @media screen and (max-width: 1264px) {
    padding-top: 0 !important;
  }
}

.upload-btns {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;

  flex-direction: column;
}

.myinput {
  margin-right: 10px;
}

.toggle-btn {
  background: transparent !important;
}
</style>
